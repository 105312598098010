<template>
  <form @submit.prevent="onsubmit">
    <va-input
      v-model="email"
      type="email"
      :label="$t('auth.email')"
    />

    <va-input
      v-model="password"
      type="password"
      :label="$t('auth.password')"
    />
    <div v-if="wrongPassword" style="color: red;">Wrong credentials</div>
    <div class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0">{{ $t('auth.login') }}</va-button>
    </div>
  </form>
</template>

<script>
import * as axios from 'axios'

export default {
  name: 'login',
  data () {
    return {
      email: '',
      password: '',
      wrongPassword: false,
    }
  },
  methods: {
    onsubmit () {
      this.wrongPassword = false
      axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/uk/admin/login`, {
        email: this.email,
        password: this.password,
      })
        .then(response => {
          console.log(response)
          localStorage.token = response.data
          this.$router.push({ name: 'dashboard' })
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 401) {
            this.wrongPassword = true
          }
        })
    },
  },
}
</script>

<style lang="scss">
</style>
